<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n code="entities.employmentContract.menu"></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="app-content-page">
      <h1 class="app-content-title">
        <app-i18n code="entities.employmentContract.list.title"></app-i18n>
      </h1>

      <app-employment-contract-list-toolbar></app-employment-contract-list-toolbar>
      <app-employment-contract-list-filter></app-employment-contract-list-filter>
      <app-employment-contract-list-table :isInPojectImplementation="false"></app-employment-contract-list-table>
    </div>
  </div>
</template>

<script>
import EmploymentContractListFilter from '@/modules/employment-contract/components/employment-contract-list-filter.vue';
import EmploymentContractListTable from '@/modules/employment-contract/components/employment-contract-list-table.vue';
import EmploymentContractListToolbar from '@/modules/employment-contract/components/employment-contract-list-toolbar.vue';

export default {
  name: 'app-employment-contract-list-page',

  components: {
    [EmploymentContractListFilter.name]: EmploymentContractListFilter,
    [EmploymentContractListTable.name]: EmploymentContractListTable,
    [EmploymentContractListToolbar.name]: EmploymentContractListToolbar,
  },
};
</script>

<style>
</style>
