<template>
  <div>
    <el-table
      :border="!isInPojectImplementation"
      :data="rows"
      @sort-change="doChangeSort"
      ref="table"
      row-key="id"
      v-loading="loading"
    >
      <!-- <el-table-column
        type="selection"
        width="55"
        v-if="!isInPojectImplementation"
      ></el-table-column> -->

      <el-table-column
        :label="fields.project.label"
        :prop="fields.project.name"
        v-if="!isInPojectImplementation"
      >
        <template slot-scope="scope">
          <app-list-item-relation-to-one
            :label="fields.project.label"
            :readPermission="fields.project.readPermission"
            :navigatePermission="fields.project.navigatePermission"
            :url="fields.project.viewUrl"
            :value="presenter(scope.row, 'project')"
          ></app-list-item-relation-to-one>
        </template>
      </el-table-column>

      <el-table-column
        :label="fields.teamMember.label"
        :prop="fields.teamMember.name"
      >
        <template slot-scope="scope">
          <app-list-item-relation-to-one
            :label="fields.teamMember.label"
            :readPermission="fields.teamMember.readPermission"
            :navigatePermission="fields.teamMember.navigatePermission"
            :url="fields.teamMember.viewUrl"
            :value="presenter(scope.row, 'teamMember')"
          ></app-list-item-relation-to-one>
        </template>
      </el-table-column>

      <el-table-column
        :label="fields.contractDraft.label"
        :prop="fields.contractDraft.name"
        align="center"
      >
        <template v-slot="scope">
          <app-list-item-file
            :permissions="fields.contractDraft.permissions"
            :value="presenter(scope.row, 'contractDraft')"
          ></app-list-item-file>
        </template>
      </el-table-column>

      <el-table-column
        :label="fields.contract.label"
        :prop="fields.contract.name"
        align="center"
      >
        <template slot-scope="scope">
          <app-list-item-file
            :permissions="fields.contract.permissions"
            :value="presenter(scope.row, 'contract')"
          ></app-list-item-file>
        </template>
      </el-table-column>

      <el-table-column
        :label="fields.contractType.label"
        :prop="fields.contractType.name"
        sortable="custom"
      >
        <template slot-scope="scope">{{
          presenter(scope.row, 'contractType')
        }}</template>
      </el-table-column>

      <el-table-column
        :label="fields.status.label"
        :prop="fields.status.name"
        sortable="custom"
        width="150"
      >
        <template v-slot="scope">
          <el-tag
            :type="getStatusTagType(scope.row[fields.status.name])"
            v-if="isStatusNull(scope.row[fields.status.name])"
            >{{ presenter(scope.row, 'status') }}</el-tag
          >
        </template>
      </el-table-column>

      <el-table-column
        :label="fields.date.label"
        :prop="fields.date.name"
        sortable="custom"
        width="180"
      >
        <template v-slot="scope">{{
          presenter(scope.row, 'date')
        }}</template>
      </el-table-column>

      <el-table-column
        :fixed="isMobile ? undefined : 'right'"
        align="center"
        v-if="isInPojectImplementation"
        width="130"
      >
        <template slot-scope="scope">
          <div class="table-actions">
            <el-button
              type="text"
              @click="doOpenModal(scope.row)"
              v-if="hasPermissionToEdit && !isReleased(scope.row)"
            >
              <app-i18n code="common.edit"></app-i18n>
            </el-button>

            <el-button
              @click="doDestroyWithConfirm(scope.row.id)"
              type="text"
              v-if="
                hasPermissionToDestroy &&
                  !(destroyLoading || isReleased(scope.row))
              "
            >
              <app-i18n code="common.destroy"></app-i18n>
            </el-button>
          </div>
        </template>
      </el-table-column>

      <el-table-column
        :fixed="isMobile ? undefined : 'right'"
        :label="getTranslation('common.actions')"
        align="center"
        v-if="isReleaseMode || !isInPojectImplementation"
        width="200"
      >
        <template v-slot="scope">
          <div class="table-actions">
            <el-button
              :disabled="scope.row[fields.status.name] === 'released'"
              @click="doReleaseContract(scope.row)"
              plain
              size="mini"
              type="success"
              v-if="hasPermissionToRelease"
            >
              <app-i18n code="common.release"></app-i18n>
            </el-button>

            <el-button
              :disabled="scope.row[fields.status.name] === 'revise'"
              @click="doReviseContract(scope.row)"
              plain
              size="mini"
              type="danger"
              v-if="hasPermissionToRelease"
            >
              <app-i18n code="common.revise"></app-i18n>
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <div class="el-pagination-wrapper">
      <el-pagination
        :current-page="pagination.currentPage || 1"
        :disabled="loading"
        :layout="paginationLayout"
        :total="count"
        @current-change="doChangePaginationCurrentPage"
        @size-change="doChangePaginationPageSize"
      ></el-pagination>
    </div>
    <portal to="modal">
      <app-employment-contract-form-modal
        :visible="dialogVisible"
        :record="record"
        v-if="dialogVisible"
        @success="onModalSuccess"
        @close="onModalClose"
      ></app-employment-contract-form-modal>
    </portal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { EmploymentContractModel } from '@/modules/employment-contract/employment-contract-model';
import EmploymentContractFormModal from '@/modules/employment-contract/components/employment-contract-form-modal';
import { EmploymentContractPermissions } from '@/modules/employment-contract/employment-contract-permissions';
import { i18n } from '@/i18n';
import moment from 'moment';
import { EventBus } from '@/eventBus';

const { fields } = EmploymentContractModel;

export default {
  name: 'app-employment-contract-list-table',

  props: ['isInPojectImplementation', 'releaseMode'],

  components: {
    [EmploymentContractFormModal.name]: EmploymentContractFormModal,
  },

  data() {
    return {
      dialogVisible: false,
      record: null,
    };
  },

  async mounted() {
    this.doMountTable(this.$refs.table);
  },

  computed: {
    ...mapGetters({
      rows: 'employmentContract/list/rows',
      count: 'employmentContract/list/count',
      loading: 'employmentContract/list/loading',
      pagination: 'employmentContract/list/pagination',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      destroyLoading: 'employmentContract/destroy/loading',
      paginationLayout: 'layout/paginationLayout',
    }),

    hasPermissionToEdit() {
      return new EmploymentContractPermissions(this.currentUser).edit;
    },

    hasPermissionToDestroy() {
      return new EmploymentContractPermissions(this.currentUser)
        .destroy;
    },

    hasPermissionToRelease() {
      return new EmploymentContractPermissions(this.currentUser)
        .release;
    },

    isReleaseMode() {
      return this.releaseMode === undefined ? false : true;
    },

    inProjectRelease() {
      return this.isInPojectImplementation;
    },

    fields() {
      return fields;
    },
  },

  /*  filters: {
    //TODO change this to be converted to locale
    moment: function (date) {
      if (date) {
        return moment(date).format('DD.MM.YYYY');
      }
    },
  }, */

  methods: {
    ...mapActions({
      doChangeSort: 'employmentContract/list/doChangeSort',
      doChangePaginationCurrentPage:
        'employmentContract/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize:
        'employmentContract/list/doChangePaginationPageSize',
      doMountTable: 'employmentContract/list/doMountTable',
      doDestroy: 'employmentContract/destroy/doDestroy',
      doUpdateStatus: 'employmentContract/list/doUpdateStatus',
      doReleaseRequest: 'employmentContract/list/doReleaseRequest',
    }),

    presenter(row, fieldName) {
      return EmploymentContractModel.presenter(row, fieldName);
    },

    isReleased(row) {
      return row.status === 'released' && row.contract.length > 0;
    },

    getStatusTagType(status) {
      if (status === 'released') {
        return 'success';
      }
      if (status === 'releaseRequested') {
        return 'primary';
      }
      if (status === 'revise') {
        return 'danger';
      }
    },

    isStatusNull(status) {
      return status === null ? false : true;
    },

    getTranslation(value) {
      return i18n(value);
    },

    doReviseContract(row) {
      const { id, updatedAt } = row;
      const values = {
        status: 'revise',
        date: moment().format('YYYY-MM-DD'),
        updatedAt,
      };

      this.doUpdateStatus({ id, values });
    },

    doReleaseContract(row) {
      const { id, updatedAt } = row;
      const values = {
        status: 'released',
        date: moment().format('YYYY-MM-DD'),
        updatedAt,
      };

      this.doUpdateStatus({ id, values });
    },

    doEmploymentContractsRequestRelease(id) {
      this.doReleaseRequest(id);
    },

    async doDestroyWithConfirm(id) {
      try {
        await this.$confirm(
          i18n('common.areYouSure'),
          i18n('common.confirm'),
          {
            confirmButtonText: i18n('common.yes'),
            cancelButtonText: i18n('common.no'),
            type: 'warning',
          },
        );

        await this.doDestroy(id);

        if (this.isInPojectImplementation) {
          return this.$emit('removeEmploymentContract', { id });
        }
      } catch (error) {
        // no
      }
    },

    doOpenModal(payload) {
      this.record = payload;
      this.dialogVisible = true;
    },

    onModalSuccess() {
      this.dialogVisible = false;
      EventBus.$emit('updateEmploymentContractsList');
    },

    onModalClose() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style scoped>
.el-tag {
  width: fit-content;
}
</style>
